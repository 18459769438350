import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { Link } from "react-router-dom";
import {
  useGetAllAnchorQuery,
  useGetAllAreasQuery,
  useGetAllEventsQuery,
  useGetAllUsersQuery,
  useGetAllZonesQuery,
} from "../../Slices/apisSlice";

export const data = [
  ["Element", "Merchandise", "Sales"],
  ["Jan", 1000, 1500],
  ["Feb", 1400, 1300],
  ["Mar", 1000, 1500],
  ["Apr", 1500, 1100],
  ["May", 1300, 1000],
  ["Jun", 1100, 1500],
  ["Jul", 1300, 250],
  ["Aug", 1300, 800],
  ["Sept", 990, 190],
  ["Oct", 149, 150],
  ["Nov", 105, 500],
  ["Dec", 980, 1500],
];

export const details = [
  ["Year", "Sales", "Expenses"],
  ["12 Nov", 1000, 400],
  ["13 Nov", 1170, 460],
  ["14 Nov", 660, 1120],
  ["15 Nov", 1030, 540],
  ["16 Nov", 1030, 540],
  ["17 Nov", 1030, 540],
  ["18 Nov", 1030, 540],
];

export const options = {
  legend: "none",
  colors: ["#FF007A", "#3B3B8E"],
  interpolateNulls: false,

  hAxis: {
    viewWindow: { min: 0, max: 7 },
  },
};

export const transaction = [
  ["Element", "transaction"],
  ["12", 15],
  ["13", 10],
  ["14", 15],
  ["15", 5],
  ["16", 10],
  ["17", 5],
  ["18", 15],
  ["19", 8],
  ["20", 10],
];

export const category = [
  ["Categories", "Total"],
  ["Category 1", 11],
  ["Category 2", 2],
  ["Category 3", 2],
  ["Category 4", 2],
  ["Category 5", 7],
];
function Index() {
  const [user, setUser] = useState([]);
  const [events, setEvents] = useState([]);
  const [anchors, setAnchors] = useState([]);
  const [zones, setZones] = useState([]);
  const [areas, setAreas] = useState([]);
  const { data } = useGetAllUsersQuery();
  const { data: zone } = useGetAllZonesQuery();
  const { data: area } = useGetAllAreasQuery();
  const { data: anchor } = useGetAllAnchorQuery();
  const { data: event } = useGetAllEventsQuery();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (data) {
      setUser(data);
    }
    if (event) {
      setEvents(event.eventList);
    }
    if (anchor) {
      setAnchors(anchor);
    }
    if (area) {
      setAreas(area.data);
    }
    if (zone) {
      setZones(zone);
    }
  }, [anchor, area, data, event, zone]);

  return (
    <>
      <div className="flex justify-between items-center px-5 mt-5 gap-x-2 ">
        <h2 className="text-gray-900   text-lg font-extrabold text-left rounded-2xl focus:outline-none block  ">
          Overview
        </h2>
      </div>

      <div className="p-2 lg:p-3  w-full mt-2   ">
        <div className="">
          <div className="flex gap-3 w-full items-center justify-center ">
            <div className=" text-center w-full p-3.5 lg:py-8 lg:px-6 bg-white rounded-xl ">
              <div className="flex items-center font-bold text-sm text-[#414D52]  gap-x-1">
                Total Users
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7366_277628)">
                    <path
                      d="M7.99967 15.1663C11.6816 15.1663 14.6663 12.1816 14.6663 8.49967C14.6663 4.81778 11.6816 1.83301 7.99967 1.83301C4.31778 1.83301 1.33301 4.81778 1.33301 8.49967C1.33301 12.1816 4.31778 15.1663 7.99967 15.1663Z"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 5.83301V8.49967"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 11.167H8.00667"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7366_277628">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex items-center gap-2  font-black text-[#022045]  text-3xl ">
                {user.length}
                <div className="flex items-center  flex-1 text-green-500 text-base font-bold">
                  +2.5%
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="font-light text-sm text-left ">
                <Link
                  to="/dashboard/users"
                  className=" text-xs font-normal text-[#414D52] underline underline-offset-4 decoration-1  "
                >
                  View all Users
                </Link>
              </div>
            </div>

            <div className=" text-center w-full p-3.5 lg:py-8 lg:px-6 bg-white rounded-xl ">
              <div className="flex items-center font-bold text-sm text-[#414D52]  gap-x-1">
                Total Areas
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7366_277628)">
                    <path
                      d="M7.99967 15.1663C11.6816 15.1663 14.6663 12.1816 14.6663 8.49967C14.6663 4.81778 11.6816 1.83301 7.99967 1.83301C4.31778 1.83301 1.33301 4.81778 1.33301 8.49967C1.33301 12.1816 4.31778 15.1663 7.99967 15.1663Z"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 5.83301V8.49967"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 11.167H8.00667"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7366_277628">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex items-center gap-2  font-black text-[#022045]  text-3xl ">
                {areas.length}
                <div className="flex items-center  flex-1 text-green-500 text-base font-bold">
                  +2.5%
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="font-light text-sm text-left ">
                <Link
                  to="/dashboard/zones"
                  className=" text-xs font-normal text-[#414D52] underline underline-offset-4 decoration-1  "
                >
                  View all Areas
                </Link>
              </div>
            </div>

            <div className=" text-center w-full p-3.5 lg:py-8 lg:px-6 bg-white rounded-xl ">
              <div className="flex items-center font-bold text-sm text-[#414D52]  gap-x-1">
                Total Devotionals
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7366_277628)">
                    <path
                      d="M7.99967 15.1663C11.6816 15.1663 14.6663 12.1816 14.6663 8.49967C14.6663 4.81778 11.6816 1.83301 7.99967 1.83301C4.31778 1.83301 1.33301 4.81778 1.33301 8.49967C1.33301 12.1816 4.31778 15.1663 7.99967 15.1663Z"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 5.83301V8.49967"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 11.167H8.00667"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7366_277628">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex items-center gap-2  font-black text-[#022045]  text-3xl ">
                {anchors.length}
                <div className="flex items-center  flex-1 text-green-500 text-base font-bold">
                  +2.5%
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="font-light text-sm text-left ">
                <Link
                  to="/dashboard/devotionals"
                  className=" text-xs font-normal text-[#414D52] underline underline-offset-4 decoration-1  "
                >
                  View all Devotionals
                </Link>
              </div>
            </div>

            <div className=" text-center w-full p-3.5 lg:py-8 lg:px-6 bg-white rounded-xl ">
              <div className="flex items-center font-bold text-sm text-[#414D52]  gap-x-1">
                Total Events
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7366_277628)">
                    <path
                      d="M7.99967 15.1663C11.6816 15.1663 14.6663 12.1816 14.6663 8.49967C14.6663 4.81778 11.6816 1.83301 7.99967 1.83301C4.31778 1.83301 1.33301 4.81778 1.33301 8.49967C1.33301 12.1816 4.31778 15.1663 7.99967 15.1663Z"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 5.83301V8.49967"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 11.167H8.00667"
                      stroke="#95A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7366_277628">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex items-center gap-2  font-black text-[#022045]  text-3xl ">
                {events.length}
                <div className="flex items-center  flex-1 text-green-500 text-base font-bold">
                  +2.5%
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="font-light text-sm text-left ">
                <Link
                  to="/dashboard/events"
                  className=" text-xs font-normal text-[#414D52] underline underline-offset-4 decoration-1  "
                >
                  View all Events
                </Link>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-x-1 mt-3">
            <div className="col-span-4 bg-white  rounded-lg p-4  lg:pt-5">
              <div className="flex justify-between items-center  gap-x-2 ">
                <h2 className="text-[#022045]   text-lg font-extrabold text-left border-[#022045] block border-b border-dashed ">
                  Active Users
                </h2>
              </div>

              <div className="sm:-mx-4">
                <Chart
                  chartType="AreaChart"
                  width="100%"
                  height={"300px"}
                  data={transaction}
                  options={
                    // Chart options
                    {
                      legend: "none",
                      colors: ["#FF007A"],
                    }
                  }
                />
              </div>

              <div className="flex flex-col justify-between mx-5 items-center mt-8">
                <h2 className="text-[#495454]   text-sm font-semibold text-left border-[#022045]  ">
                  Active Users
                </h2>
                <h2 className="text-[#071717]   text-6xl font-bold text-left border-[#022045] ">
                  {user.length}
                </h2>
              </div>
            </div>
            <div className="col-span-4 bg-white  rounded-lg p-4  lg:pt-5">
              <div className="flex justify-between items-center  gap-x-2 mb-3 ">
                <h2 className="text-[#022045]   text-lg font-extrabold text-left border-[#022045] block border-b border-dashed ">
                  Locations/Zones
                </h2>
              </div>

              <img src="../map.png" alt="" srcset="" className="w-auto mt-5" />

              <div className="flex flex-col justify-between mx-5 items-center mt-8">
                <h2 className="text-[#495454]   text-sm font-semibold text-left border-[#022045]  ">
                  Number of Zones
                </h2>
                <h2 className="text-[#071717]   text-6xl font-bold text-left border-[#022045] ">
                  {zones.length}
                </h2>
              </div>
            </div>

            <div className="col-span-4 bg-white  rounded-lg p-4  lg:pt-5">
              <div className="flex justify-between items-center  gap-x-2 ">
                <h2 className="text-[#022045]   text-lg font-extrabold text-left border-[#022045] block border-b border-dashed ">
                  Total Users
                </h2>
              </div>

              <div className=" -mx-4 ">
                {" "}
                <Chart
                  chartType="ColumnChart"
                  data={details}
                  options={options}
                  width={"100%"}
                  height={"300px"}
                />
              </div>
              <div className="flex justify-between mb-3 mx-2 border p-3 rounded-lg">
                <div className="flex text-sm  leading-none font-semibold text-gray-900 gap-x-1">
                  <span className="px-1.5 bg-[#FF007A] rounded-full"></span>{" "}
                  Downloads
                </div>
                <div className="flex text-sm  leading-none font-semibold text-gray-900 gap-x-1">
                  <span className="px-1.5 bg-[#3B3B8E] rounded-full"></span>{" "}
                  Members
                </div>
              </div>

              <div className="border-t border-dashed mx-2">
                <h2 className="text-[#022045] py-1  text-base font-extrabold text-left  border-dashed ">
                  Users
                </h2>
                <div className="flex items-center justify-between text-sm  leading-none font-semibold text-gray-900 py-1">
                  <span className="text-[#414D52]">Downloads</span>
                  <span className="text-[#022045] text-lg font-black">32%</span>
                </div>
                <div className="flex items-center justify-between text-sm  leading-none font-semibold text-gray-900 py-1">
                  <span className="text-[#414D52]">Members</span>
                  <span className="text-[#022045] text-lg font-black">64%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
