import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../components/Spinner";
import { useCreateAdminMutation } from "../../../Slices/apisSlice";

function Index() {
  const [createModal, setCreateModal] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [createAdmin, { isLoading }] = useCreateAdminMutation();
  const { register, handleSubmit } = useForm();
  const createHandler = async (data) => {
    try {
      await createAdmin(data).unwrap();
      toast.success("Create Admin Successful");
    } catch (err) {
      toast.error(err?.data?.msg || err.error);
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="p-2 lg:p-6  max-w-4xl ">
        <div className="p-10 bg-[#fff] rounded-2xl h-[30rem]">
          <h2 className="text-[#000000] font-extrabold text-2xl p-4 text-left">
            System Settings & Security
          </h2>

          <div class="min-w-full ">
            <div className="my-2 border-b flex items-center justify-between p-5">
              <div className="">
                <h2 className="text-[#0C0C0C] font-extrabold text-lg py-1 text-left  block ">
                  Manage Accounts
                </h2>
                <h2 className="text-[#4E4E4E] text-xs font-light text-left flex">
                  Change and monitor Admins activities
                </h2>
              </div>

              <div class="px-3 text-sm text-[#fff] whitespace-nowrap ">
                <Link to="accounts" className=" px-3 rounded-lg">
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L6 6L1 11" stroke="black" stroke-width="2" />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="my-2 border-b flex items-center justify-between p-5">
              <div className="">
                <h2 className="text-[#0C0C0C] font-extrabold text-lg py-1 text-left  block ">
                  Configure system settings
                </h2>
                <h2 className="text-[#4E4E4E] text-xs font-light text-left flex">
                  Change system configurations
                </h2>
              </div>

              <div class="px-3 text-sm text-[#fff] whitespace-nowrap ">
                <Link to="system_configuration" className=" px-3 rounded-lg">
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L6 6L1 11" stroke="black" stroke-width="2" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {createModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-end">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCreateModal(false);
                  }}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm  ml-auto inline-flex items-center "
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Create New Admin Account
              </h3>

              <form onSubmit={handleSubmit(createHandler)}>
                <div>
                  <label
                    for="username"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Full Name *
                  </label>
                  <input
                    type="text"
                    name="Name"
                    required
                    {...register("name")}
                    placeholder="Full Name"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div>
                  <label
                    for="username"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Email *
                  </label>
                  <input
                    type="email"
                    name="email"
                    required
                    {...register("email")}
                    placeholder="Email Address"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div>
                  <label
                    for="role"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Role *
                  </label>
                  <input
                    type="text"
                    name="role"
                    required
                    {...register("role")}
                    value="Admin"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div className="mt-2">
                  <label
                    for="password"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Password *
                  </label>
                  <div className="flex ">
                    <input
                      {...register("password")}
                      required
                      type={passwordType}
                      name="password"
                      id="password"
                      placeholder="Your Password"
                      className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                      <button
                        type="button"
                        onClick={() => {
                          if (passwordType === "password") {
                            setPasswordType("text");
                            return;
                          }
                          setPasswordType("password");
                        }}
                        className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                      >
                        {passwordType === "password" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_5982_274025)">
                              <path
                                d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003"
                                stroke="#757E7E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1 1L23 23"
                                stroke="#757E7E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_5982_274025">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                              fill="#757E7E"
                            />
                          </svg>
                        )}
                      </button>
                    </span>
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#284C84] rounded-sm hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                  >
                    {isLoading ? <Spinner /> : "Create New Admin Account"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Index;
