import React from 'react';

function Loading() {
	return (
		<section>
			<div class="container  p-4  mx-auto animate-pulse ">
				<div class="w-full flex justify-between items-center gap-x-5">
					<div class="w-28 h-4 lg:h-7 bg-gray-300 rounded-lg  "></div>

					<div>
						<h1 className="w-full h-2 bg-gray-200 rounded-lg "></h1>
						<p class="w-24 h-2 mt-4 bg-gray-200 rounded-lg "></p>
					</div>
					<div>
						<h1 className="w-full h-2 bg-gray-200 rounded-lg "></h1>
						<p class="w-24 h-2 mt-4 bg-gray-200 rounded-lg "></p>
					</div>
					<div>
						<h1 className="w-full h-2 bg-gray-200 rounded-lg "></h1>
						<p class="w-24 h-2 mt-4 bg-gray-200 rounded-lg "></p>
					</div>
					<div>
						<h1 className="w-full h-2 bg-gray-200 rounded-lg "></h1>
						<p class="w-24 h-2 mt-4 bg-gray-200 rounded-lg "></p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Loading;
