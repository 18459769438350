import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { setCredentials } from "../Slices/authSlice";
import { useLoginMutation } from "../Slices/apisSlice";
import Spinner from "../components/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

function Login() {
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);
  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const loginHandler = async (data) => {
    try {
      const res = await login(data).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate("dashboard/index");
    } catch (err) {
      toast.error(err?.data?.msg || err.error, {
        position: "top-left",
      });
    }
  };

  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/dashboard/index";

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  return (
    <>
      <div class="lg:grid grid-cols-12 gap-x-5 font-Lato">
        <div
          class="col-span-4 hidden bg-cover lg:block"
          style={{
            "background-image": "url('../bg.png')",
          }}
        >
          <div className="w-[18.5rem] mx-auto text-left my-20">
            <h3
              className="text-2xl lg:text-4xl text-left  font-extrabold mb-3 leading-6 text-white capitalize "
              id="modal-title"
            >
              Welcome
            </h3>
            <p className="mt-5 text-xs text-white font-light ">
              {" "}
              In every student that steps into the campus is a potential to be
              great and useful to God’s kingdom and humanity.
            </p>

            <p className="mt-5 text-xs text-white font-light ">
              The Nigeria Fellowship of Evangelical Students (NIFES) is an
              indigenous, non-governmental and interdenominational organization
              of evangelical Christian students in Nigeria tertiary
              institutions. We reach out to students through small group Bible
              Studies, Evangelism, Prayers meetings, Large Group Meetings,
              Biblical Discipleship, Life-changing Leadership Training and
              Conference. In them is the great reservoir that contains the
              answers to this world’s unanswered questions and solutions to the
              world’s problems.
            </p>
          </div>
        </div>
        <div class="col-span-8 w-full px-4  h-screen py-12  ">
          <div class="flex justify-center mb-5 sm:my-5 mx-auto">
            <img class="w-auto h-20" src="../logo.png" alt="" />
          </div>

          <h3
            class="text-2xl lg:text-4xl text-center font-extrabold   leading-6 text-gray-900  capitalize "
            id="modal-title"
          >
            Login to Admin
          </h3>

          <form
            class="mt-4 w-[30rem] mx-auto"
            onSubmit={handleSubmit(loginHandler)}
          >
            <div class="mb-3">
              <label class="relative bg-white w-12 ml-5 rounded-full text-center -mb-2 z-10 block text-xs text-[#3B3B8E] font-semibold">
                Email
              </label>
              <input
                type="email"
                required
                {...register("email")}
                class="py-4 px-5 border bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
              />
            </div>

            <div class="mb-3">
              <label class="relative bg-white w-20 ml-5 rounded-full text-center -mb-2 z-10 block text-xs text-[#3B3B8E] font-semibold">
                Password
              </label>
              <div className="flex -mr-px">
                <input
                  required
                  {...register("password")}
                  type={passwordType}
                  class="py-4 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                />
                <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                  <button
                    type="button"
                    onClick={() => {
                      if (passwordType === "password") {
                        setPasswordType("text");
                        return;
                      }
                      setPasswordType("password");
                    }}
                    className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                  >
                    {passwordType === "password" ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_5982_274025)">
                          <path
                            d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003"
                            stroke="#757E7E"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 1L23 23"
                            stroke="#757E7E"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5982_274025">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                          fill="#757E7E"
                        />
                      </svg>
                    )}
                  </button>
                </span>
              </div>
            </div>

            <div class="flex flex-col my-5 w-full">
              <button
                type="submit"
                value="submit"
                disabled={isLoading}
                class="w-full bg-[#3B3B8E] rounded-3xl p-3.5 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
              >
                {isLoading ? <Spinner /> : "Login"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
