import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormattedDate from "../../../components/FormattedDate";
import PageLoading from "../../../components/PageLoading";
import { useGetAllPrayerQuery } from "../../../Slices/apisSlice";
import { BASE_URL } from "../../../utils";

function Request() {
  const [modal, setModal] = useState(false);
  const [prayer, setPrayer] = useState([]);
  const [details, setDetails] = useState([]);

  const { data, isLoading, error } = useGetAllPrayerQuery();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (data) {
      setPrayer(data);
    }
  }, [data]);

  const [searchQuery, setSearchQuery] = useState("");
  const filteredPrayer = prayer.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.token;

  const getHandler = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}admin/prayer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });

      const response = res.data;
      setDetails(response);
      setModal(true);
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center px-5 mt-5  ">
        <div className="flex items-center gap-x-10">
          <h2 className="text-gray-900   text-lg font-extrabold text-left rounded-2xl focus:outline-none block  ">
            Requests
          </h2>
        </div>
      </div>
      <div className="p-2 lg:p-6  max-w-5xl mt-5">
        <div className="p-2 bg-[#fff] rounded-2xl">
          <div className="flex justify-between items-center my-3 px-5">
            <div className="relative w-[30rem]">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.8866 15.2537 12.5988 14.0402 13.8576C14.0064 13.8837 13.9738 13.9123 13.9428 13.9433C13.9118 13.9743 13.8833 14.0068 13.8572 14.0406C12.5984 15.2539 10.8863 16 9 16C5.13401 16 2 12.866 2 9ZM14.6174 16.0321C13.0778 17.2636 11.1249 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.1251 17.2634 13.0782 16.0317 14.618L19.707 18.2933C20.0975 18.6838 20.0975 19.317 19.707 19.7075C19.3165 20.098 18.6833 20.098 18.2928 19.7075L14.6174 16.0321Z"
                    fill="#7B7C7C"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-white text-gray-900 text-sm rounded-2xl focus:outline-none block w-full pl-10 p-3 placeholder-bold"
                placeholder="Search"
                required
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="flex justify-between   py-1 items-center gap-x-3 ">
              {/* <button
                className="flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
                aria-expanded="false"
              >
                Show: 10
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.2932 5.33203C12.7124 5.33203 12.9455 5.817 12.6836 6.14438L8.3906 11.5107C8.19043 11.7609 7.80989 11.7609 7.60973 11.5107L3.31671 6.14438C3.0548 5.817 3.28789 5.33203 3.70714 5.33203L12.2932 5.33203Z"
                    fill="#7B7B7B"
                  />
                </svg>
              </button> */}
              <button
                className="flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
                aria-expanded="false"
              >
                Filters
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.59993 1.3999H12.3999C13.1333 1.3999 13.7333 1.9999 13.7333 2.73324V4.1999C13.7333 4.73324 13.3999 5.3999 13.0666 5.73324L10.1999 8.26657C9.79993 8.5999 9.53327 9.26657 9.53327 9.7999V12.6666C9.53327 13.0666 9.2666 13.5999 8.93327 13.7999L7.99994 14.3999C7.13327 14.9332 5.93327 14.3332 5.93327 13.2666V9.73324C5.93327 9.26657 5.6666 8.66657 5.39994 8.33324L2.8666 5.66657C2.53327 5.33324 2.2666 4.73324 2.2666 4.33324V2.7999C2.2666 1.9999 2.8666 1.3999 3.59993 1.3999Z"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.28667 1.3999L4 6.66657"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <button
                className="flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
                aria-expanded="false"
              >
                Sort
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 4.6665H14"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4 8H12"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M6.66699 11.3335H9.33366"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <section className="container bg-[#FFFFFF] lg:bg-transparent rounded mx-auto h-[50rem] ">
            <div className="flex flex-col">
              <div className="overflow-x-auto rounded-lg h-[38rem] scrollbar-hide">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden md:rounded-lg">
                    {isLoading ? (
                      <div className="pb-5">
                        <PageLoading />
                      </div>
                    ) : error ? (
                      <div className="pt-5">
                        <PageLoading />
                      </div>
                    ) : (
                      <table className="min-w-full ">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 px-4 text-xs lg:text-sm font-normal text-left rtl:text-right text-gray-500 "
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-xs lg:text-sm  font-normal text-left rtl:text-right text-gray-500 "
                            >
                              Name
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-xs lg:text-sm  gap-1 font-normal  text-left rtl:text-right text-gray-500  "
                            >
                              <div className="flex items-center gap-x-2  ">
                                Date
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="  ">
                          {filteredPrayer
                            .slice()
                            .reverse()
                            .map((item, index) => (
                              <tr
                                className={index % 2 === 0 ? "" : "bg-gray-50"}
                                key={index}
                              >
                                <td className="pl-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                  {index + 1}
                                </td>

                                <td className="px-3 py-1 text-sm text-[#337FEF] whitespace-nowrap ">
                                  <div className="flex items-center gap-x-2">
                                    <div
                                      className="rounded-full bg-center bg-cover h-[2rem] w-[2rem] flex items-center justify-center"
                                      style={{
                                        backgroundImage:
                                          "url('../../Avatar.png')",
                                      }}
                                    >
                                      <span className=" text-white ">
                                        {item.name
                                          .split(" ")
                                          .map((i) => i.charAt(0))}
                                      </span>
                                    </div>
                                    <div className="flex flex-col -space-y-1">
                                      <span className="font-bold text-[#022045]">
                                        {item.name}
                                      </span>
                                      <span className="font-light text-[#69797F]">
                                        {item.email}
                                      </span>
                                    </div>
                                  </div>
                                </td>

                                <td className="px-3 py-4 text-sm text-gray-500  whitespace-nowrap">
                                  <FormattedDate date={item?.createdAt} />
                                </td>

                                <td className=" py-1 text-sm text-gray-500  whitespace-nowrap">
                                  <button
                                    onClick={() => getHandler(item._id)}
                                    className="flex gap-x-1 text-center justify-center w-full px-2 py-2 text-sm text-gray-800 capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                                        fill="#757E7E"
                                      />
                                    </svg>
                                    View
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-md sm:p-6  sm:align-middle">
              <div className="flex items-start justify-between ">
                <div className=" flex justify-between font-semibold py-2">
                  <p className="text-xs  text-gray-900 ">Prayer Request</p>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(false);
                  }}
                  type="button"
                  className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <div className="">
                <div className=" my-3 h-full rounded-lg  bg-white   ">
                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10 rounded-t-md items-center py-1">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Full Name
                    </p>
                    <div className="text-right">
                      <p className="text-xs font-bold">{details.name}</p>
                    </div>
                  </div>
                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10  items-center py-1 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">Email</p>
                    <div className="text-right">
                      <p className="text-xs font-bold">{details.email}</p>
                    </div>
                  </div>
                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10  items-center py-1 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Phone Number
                    </p>
                    <div className="text-right">
                      <p className="text-xs font-bold">{details.phone}</p>
                    </div>
                  </div>
                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10 items-center py-1 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Date Added
                    </p>
                    <div className="text-right">
                      <p className="text-xs font-bold">
                        <FormattedDate date={details?.createdAt} />
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10  py-2 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Content
                    </p>
                    <div className="text-right">
                      <p className="text-xs font-semibold">{details.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {deleteModal && (
				<div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
					<div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
						<span
							className="hidden sm:inline-block sm:h-screen sm:align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>

						<div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
							<div className="flex items-start justify-between ">
								<h3 className="text-[#000] text-xl font-semibold dark:text-white">
									Confirm Event Delete
								</h3>
							</div>

							<p className="text-left py-5 text-sm text-[#141414]/70">
								Are you sure you want to delete this Event ?
							</p>
							<div className="flex gap-x-3 items-start justify-between">
								<button
									onClick={(e) => {
										e.preventDefault();
										setDeleteModal(false);
									}}
									className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
								>
									No, Cancel
								</button>
								<button
									onClick={() => {
										const data = prayerId;
										deleteHandler(data);
									}}
									className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
								>
									Yes, Confirm
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{deleteSucess && (
				<div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
					<div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
						<span
							className="hidden sm:inline-block sm:h-screen sm:align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>

						<div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
							<div className="flex items-start justify-between ">
								<h3 className="text-[#000] text-xl font-semibold dark:text-white">
									Event Deleted Successfully
								</h3>
							</div>

							<p className="text-left py-5 text-sm text-[#D3B61F]">
								You have successfully Deleted this Event
							</p>
							<div className="flex gap-x-3 items-start justify-between">
								<button
									onClick={(e) => {
										setDeleteSucess(false);
									}}
									className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
								>
									Continue
								</button>
							</div>
						</div>
					</div>
				</div>
			)} */}
    </div>
  );
}

export default Request;
