import { apiOne, apiTwo } from "./apiSlice";

export const allApiSlice = apiOne.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `auth/signin`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation } = allApiSlice;

export const authApiSlice = apiTwo.injectEndpoints({
  endpoints: (builder) => ({
    createAdmin: builder.mutation({
      query: (data) => ({
        url: `admin/makeAdmin/${data}`,
        method: "POST",
      }),
    }),
    removeAdmin: builder.mutation({
      query: (data) => ({
        url: `admin/removeAdmin/${data}`,
        method: "POST",
      }),
    }),
    getAllAdmins: builder.query({
      query: () => ({
        url: `admin/admin`,
        keepUnusedDataFor: 5,
      }),
    }),
    createAnchor: builder.mutation({
      query: (data) => ({
        url: `admin/anchor`,
        method: "POST",
        body: data,
      }),
    }),
    updateAnchor: builder.mutation({
      query: (data) => ({
        url: `admin/anchor/${data.id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    getAllAnchor: builder.query({
      query: () => ({
        url: `admin/anchor`,
        keepUnusedDataFor: 5,
      }),
    }),
    deleteAnchor: builder.mutation({
      query: (data) => ({
        url: `admin/anchor/${data}`,
        method: "DELETE",
      }),
    }),
    createWord: builder.mutation({
      query: (data) => ({
        url: `admin/word`,
        method: "POST",
        body: data,
      }),
    }),
    updateWord: builder.mutation({
      query: (data) => ({
        url: `admin/word/${data.id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    getAllWord: builder.query({
      query: () => ({
        url: `nifes/word/all`,
        keepUnusedDataFor: 5,
      }),
    }),
    deleteWord: builder.mutation({
      query: (data) => ({
        url: `admin/word/${data}`,
        method: "DELETE",
      }),
    }),

    getAllEvents: builder.query({
      query: () => ({
        url: `nifes/events`,
        keepUnusedDataFor: 5,
      }),
    }),

    createEvent: builder.mutation({
      query: (data) => ({
        url: `admin/event`,
        method: "POST",
        body: data,
      }),
    }),

    updateEvent: builder.mutation({
      query: (data) => ({
        url: `admin/event/${data.id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteEvent: builder.mutation({
      query: (data) => ({
        url: `admin/event/${data}`,
        method: "DELETE",
      }),
    }),

    createBulletin: builder.mutation({
      query: (data) => ({
        url: `admin/prayer`,
        method: "POST",
        body: data,
      }),
    }),

    getAllBulletin: builder.query({
      query: () => ({
        url: `admin/prayer_bulletin`,
        keepUnusedDataFor: 5,
      }),
    }),

    deleteBulletin: builder.mutation({
      query: (data) => ({
        url: `admin/prayer/${data}`,
        method: "DELETE",
      }),
    }),

    getAllPrayer: builder.query({
      query: () => ({
        url: `admin/prayers`,
        keepUnusedDataFor: 5,
      }),
    }),

    addEventImage: builder.mutation({
      query: (data) => ({
        url: `admin/event/gallery/${data.id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    getAllTestimony: builder.query({
      query: () => ({
        url: `admin/testimony`,
        keepUnusedDataFor: 5,
      }),
    }),

    addTestimony: builder.mutation({
      query: (data) => ({
        url: `admin/testimony`,
        method: "POST",
        body: data,
      }),
    }),

    updateTestimony: builder.mutation({
      query: (data) => ({
        url: `admin/testimony/${data.id}`,
        method: "PATCH",
        body: data,
      }),
    }),

    deleteTestimony: builder.mutation({
      query: (data) => ({
        url: `admin/testimony/${data}`,
        method: "DELETE",
      }),
    }),

    getAllUsers: builder.query({
      query: () => ({
        url: `admin/users`,
        keepUnusedDataFor: 5,
      }),
    }),

    getUser: builder.query({
      query: (id) => ({
        url: `admin/user/${id}`,
        keepUnusedDataFor: 5,
      }),
    }),
    deleteUser: builder.mutation({
      query: (data) => ({
        url: `admin/delete/${data}`,
        method: "DELETE",
      }),
    }),

    createBlog: builder.mutation({
      query: (data) => ({
        url: `admin/blog`,
        method: "POST",
        body: data,
      }),
    }),

    getAllBlog: builder.query({
      query: () => ({
        url: `admin/blog`,
        keepUnusedDataFor: 5,
      }),
    }),

    updateBlog: builder.mutation({
      query: (data) => ({
        url: `admin/blog/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteBlog: builder.mutation({
      query: (data) => ({
        url: `admin/blog/${data}`,
        method: "DELETE",
      }),
    }),
    getAllZones: builder.query({
      query: () => ({
        url: `admin/zone`,
        keepUnusedDataFor: 5,
      }),
    }),

    deleteZone: builder.mutation({
      query: (data) => ({
        url: `admin/zone/${data}`,
        method: "DELETE",
      }),
    }),
    createZone: builder.mutation({
      query: (data) => ({
        url: `admin/zone`,
        method: "POST",
        body: data,
      }),
    }),
    getAllAreas: builder.query({
      query: () => ({
        url: `nifes/area`,
        keepUnusedDataFor: 5,
      }),
    }),

    deleteArea: builder.mutation({
      query: (data) => ({
        url: `admin/area/${data}`,
        method: "DELETE",
      }),
    }),
    createArea: builder.mutation({
      query: (data) => ({
        url: `admin/area`,
        method: "POST",
        body: data,
      }),
    }),

    createIntro: builder.mutation({
      query: (data) => ({
        url: `admin/anchor_introduction`,
        method: "POST",
        body: data,
      }),
    }),

    getAllIntro: builder.query({
      query: () => ({
        url: `nifes/anchor_introduction`,
        keepUnusedDataFor: 5,
      }),
    }),

    updateIntro: builder.mutation({
      query: (data) => ({
        url: `admin/anchor_introduction/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteIntro: builder.mutation({
      query: (data) => ({
        url: `admin/anchor_introduction/${data}`,
        method: "DELETE",
      }),
    }),

    createAudio: builder.mutation({
      query: (data) => ({
        url: `admin/audio`,
        method: "POST",
        body: data,
      }),
    }),

    getAllAudio: builder.query({
      query: () => ({
        url: `nifes/audio`,
        keepUnusedDataFor: 5,
      }),
    }),

    deleteAudio: builder.mutation({
      query: (data) => ({
        url: `admin/audio/${data}`,
        method: "DELETE",
      }),
    }),
    createVideo: builder.mutation({
      query: (data) => ({
        url: `admin/video`,
        method: "POST",
        body: data,
      }),
    }),

    getAllVideo: builder.query({
      query: () => ({
        url: `nifes/video`,
        keepUnusedDataFor: 5,
      }),
    }),

    deletevideo: builder.mutation({
      query: (data) => ({
        url: `admin/video/${data}`,
        method: "DELETE",
      }),
    }),

    createAnchorYearlyTitle: builder.mutation({
      query: (data) => ({
        url: `admin/anchor_title`,
        method: "POST",
        body: data,
      }),
    }),

    getAnchorYearlyTitle: builder.query({
      query: () => ({
        url: `nifes/anchor_title`,
        keepUnusedDataFor: 5,
      }),
    }),

    updateAnchorYearlyTitle: builder.mutation({
      query: (data) => ({
        url: `admin/anchor_title/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),

    deleteAnchorYearlyTitle: builder.mutation({
      query: (data) => ({
        url: `admin/anchor_title/${data}`,
        method: "DELETE",
      }),
    }),

    createImage: builder.mutation({
      query: (data) => ({
        url: `admin/gallery`,
        method: "POST",
        body: data,
      }),
    }),

    getAllOngoingImages: builder.query({
      query: () => ({
        url: `nifes/gallery/ongoing`,
        keepUnusedDataFor: 5,
      }),
    }),

    getAllProposedImages: builder.query({
      query: () => ({
        url: `nifes/gallery/proposed`,
        keepUnusedDataFor: 5,
      }),
    }),

    deleteImage: builder.mutation({
      query: (data) => ({
        url: `admin/gallery/${data}`,
        method: "DELETE",
      }),
    }),

    createGallery: builder.mutation({
      query: (data) => ({
        url: `admin/image`,
        method: "POST",
        body: data,
      }),
    }),
    getAllGalleryImages: builder.query({
      query: () => ({
        url: `nifes/images`,
        keepUnusedDataFor: 5,
      }),
    }),
    getAllLeaders: builder.query({
      query: () => ({
        url: `nifes/leaders`,
        keepUnusedDataFor: 5,
      }),
    }),
    createLeader: builder.mutation({
      query: (data) => ({
        url: `admin/leader`,
        method: "POST",
        body: data,
      }),
    }),
    deleteLeader: builder.mutation({
      query: (data) => ({
        url: `admin/leader/${data}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateAdminMutation,
  useGetAllUsersQuery,
  useAddEventImageMutation,
  useAddTestimonyMutation,
  useCreateBlogMutation,
  useCreateEventMutation,
  useCreateAnchorMutation,
  useDeleteAnchorMutation,
  useGetAllAnchorQuery,
  useGetAllBlogQuery,
  useGetUserQuery,
  useGetAllEventsQuery,
  useDeleteEventMutation,
  useDeleteUserMutation,
  useGetAllTestimonyQuery,
  useDeleteTestimonyMutation,
  useCreateBulletinMutation,
  useGetAllBulletinQuery,
  useGetAllPrayerQuery,
  useDeleteBlogMutation,
  useDeleteZoneMutation,
  useGetAllZonesQuery,
  useGetAllAdminsQuery,
  useRemoveAdminMutation,
  useCreateZoneMutation,
  useCreateIntroMutation,
  useDeleteIntroMutation,
  useGetAllIntroQuery,
  useUpdateIntroMutation,
  useCreateWordMutation,
  useDeleteWordMutation,
  useGetAllWordQuery,
  useUpdateWordMutation,
  useDeleteBulletinMutation,
  useCreateImageMutation,
  useDeleteImageMutation,
  useGetAllOngoingImagesQuery,
  useGetAllProposedImagesQuery,
  useCreateAreaMutation,
  useDeleteAreaMutation,
  useGetAllAreasQuery,
  useCreateAnchorYearlyTitleMutation,
  useDeleteAnchorYearlyTitleMutation,
  useGetAnchorYearlyTitleQuery,
  useUpdateAnchorYearlyTitleMutation,
  useCreateAudioMutation,
  useCreateVideoMutation,
  useDeleteAudioMutation,
  useDeletevideoMutation,
  useGetAllAudioQuery,
  useGetAllVideoQuery,
  useCreateGalleryMutation,
  useGetAllGalleryImagesQuery,
  useGetAllLeadersQuery,
  useCreateLeaderMutation,
  useDeleteLeaderMutation,
} = authApiSlice;
