import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PageLoading from "../../components/PageLoading";
import {
  useCreateAnchorMutation,
  useDeleteAnchorMutation,
  useGetAllAnchorQuery,
} from "../../Slices/apisSlice";
import { BASE_URL } from "../../utils";
import Spinner from "../../components/Spinner";
import { useForm } from "react-hook-form";
import FormattedDate from "../../components/FormattedDate";

function Devotionals() {
  const [modal, setModal] = useState(false);
  const [anchor, setAnchor] = useState([]);
  const [anchorId, setAnchorId] = useState("");
  const [deleteSucess, setDeleteSucess] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [details, setDetails] = useState([]);
  const { data, isLoading, refetch, error } = useGetAllAnchorQuery();
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (data) {
      setAnchor(data);
    }
  }, [data]);

  const [searchQuery, setSearchQuery] = useState("");
  const filteredAnchor = anchor.filter((item) =>
    item.date.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.token;

  const { register, handleSubmit, setValue, reset } = useForm();
  const getHandler = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}admin/anchor/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });

      const response = res.data;
      setDetails(response);
      setModal(true);
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };

  const getEditHandler = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}admin/anchor/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      // Set form values with fetched data
      setValue("date", res.data.date);
      setValue("topic", res.data.topic);
      setValue("bibleReading", res.data.bibleReading);
      setValue("wordOfTheDay", res.data.wordOfTheDay);
      setValue("inspiringCommentary", res.data.inspiringCommentary);
      setValue("prayer", res.data.prayer);
      setValue("bibleInOneYear", res.data.bibleInOneYear);

      setEdit(true);
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };

  const updateHandler = async (data) => {
    setLoad(true);
    try {
      const res = await axios.patch(
        `${BASE_URL}admin/anchor/${anchorId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const message = res.data.message;
      setLoad(false);
      toast.success(message);
      setEdit(false);
      refetch();
    } catch (err) {
      toast.error(err?.message || err?.data.message);
    }
  };

  const [deleteword] = useDeleteAnchorMutation();
  const deleteHandler = async (deletedata) => {
    try {
      await deleteword(deletedata);
      setDeleteModal(false);
      setDeleteSucess(true);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const [createAnchor, { isLoading: loading }] = useCreateAnchorMutation();
  const createHandler = async (data) => {
    try {
      await createAnchor(data);
      toast.success("Successfully Added Devotional");
      setCreate(false);
      refetch();
    } catch (err) {
      toast.error(err?.data?.msg || err.error, {
        position: "top-left",
      });
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center px-5 mt-5  ">
        <div className="flex items-center gap-x-10">
          <h2 className="text-gray-900   text-lg font-extrabold text-left rounded-2xl focus:outline-none block  ">
            ANCHOR DEVOTIONAL
          </h2>
        </div>

        <button
          onClick={() => {
            setCreate(true);
          }}
          className="flex transition-colors duration-200   hover:text-[#4CC5D2]/50 focus:outline-none text-[#fff]  bg-[#3B3B8E] px-3 py-1 rounded-md gap-x-1 items-center "
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 3.33331V12.6666"
              stroke="CurrentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.33325 8H12.6666"
              stroke="CurrentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Add New Devetional</span>
        </button>
      </div>
      <div className="p-2 lg:p-6  w-full mt-5">
        <div className="p-2 bg-[#fff] rounded-2xl">
          <div className="flex justify-between items-center my-3 px-5">
            <div className="relative w-[30rem]">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.8866 15.2537 12.5988 14.0402 13.8576C14.0064 13.8837 13.9738 13.9123 13.9428 13.9433C13.9118 13.9743 13.8833 14.0068 13.8572 14.0406C12.5984 15.2539 10.8863 16 9 16C5.13401 16 2 12.866 2 9ZM14.6174 16.0321C13.0778 17.2636 11.1249 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.1251 17.2634 13.0782 16.0317 14.618L19.707 18.2933C20.0975 18.6838 20.0975 19.317 19.707 19.7075C19.3165 20.098 18.6833 20.098 18.2928 19.7075L14.6174 16.0321Z"
                    fill="#7B7C7C"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-white text-gray-900 text-sm rounded-2xl focus:outline-none block w-full pl-10 p-3 placeholder-bold"
                placeholder="Search"
                required
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="flex justify-between   py-1 items-center gap-x-3 ">
              {/* <button
                className="flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
                aria-expanded="false"
              >
                Show: 10
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.2932 5.33203C12.7124 5.33203 12.9455 5.817 12.6836 6.14438L8.3906 11.5107C8.19043 11.7609 7.80989 11.7609 7.60973 11.5107L3.31671 6.14438C3.0548 5.817 3.28789 5.33203 3.70714 5.33203L12.2932 5.33203Z"
                    fill="#7B7B7B"
                  />
                </svg>
              </button> */}
              <button
                className="flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
                aria-expanded="false"
              >
                Filters
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.59993 1.3999H12.3999C13.1333 1.3999 13.7333 1.9999 13.7333 2.73324V4.1999C13.7333 4.73324 13.3999 5.3999 13.0666 5.73324L10.1999 8.26657C9.79993 8.5999 9.53327 9.26657 9.53327 9.7999V12.6666C9.53327 13.0666 9.2666 13.5999 8.93327 13.7999L7.99994 14.3999C7.13327 14.9332 5.93327 14.3332 5.93327 13.2666V9.73324C5.93327 9.26657 5.6666 8.66657 5.39994 8.33324L2.8666 5.66657C2.53327 5.33324 2.2666 4.73324 2.2666 4.33324V2.7999C2.2666 1.9999 2.8666 1.3999 3.59993 1.3999Z"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.28667 1.3999L4 6.66657"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <button
                className="flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
                aria-expanded="false"
              >
                Sort
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 4.6665H14"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4 8H12"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M6.66699 11.3335H9.33366"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <section className="container bg-[#FFFFFF] lg:bg-transparent rounded mx-auto pb-5">
            <div className="flex flex-col">
              <div className="overflow-x-auto rounded-lg ">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden md:rounded-lg">
                    {isLoading ? (
                      <div className="pb-5">
                        <PageLoading />
                      </div>
                    ) : error ? (
                      <div className="pt-5">
                        <PageLoading />
                      </div>
                    ) : (
                      <table className="min-w-full ">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 px-4 text-xs lg:text-sm font-normal text-left rtl:text-right text-gray-500 "
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-xs lg:text-sm  font-normal text-left rtl:text-right text-gray-500 "
                            >
                              Topic
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-xs lg:text-sm  gap-1 font-normal text-center rtl:text-right text-gray-500  "
                            >
                              <div className="text-left w-[7rem] ">
                                Bible Reading
                              </div>
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-xs lg:text-sm  gap-1 font-normal  text-left rtl:text-right text-gray-500  "
                            >
                              <div className="flex items-center gap-x-2  ">
                                Date
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="  ">
                          {filteredAnchor
                            .slice()
                            .reverse()
                            .map((item, index) => (
                              <tr
                                className={index % 2 === 0 ? "" : "bg-gray-50"}
                                key={index}
                              >
                                <td className="pl-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                  {index + 1}
                                </td>

                                <td className="px-3 py-4 text-sm text-[#022045] font-semibold  whitespace-nowrap">
                                  {item.topic}
                                </td>

                                <td className="px-3 py-4 text-sm text-[#022045] whitespace-nowrap">
                                  {item.bibleReading}
                                </td>
                                <td className="px-3 py-4 text-sm text-[#022045]  whitespace-nowrap">
                                  <FormattedDate date={item?.date} />
                                </td>

                                <td className="px-3 py-1 text-sm text-gray-500  whitespace-nowrap">
                                  <div className=" inline-block group ">
                                    <button
                                      type="submit"
                                      className="px-0.5 ml-1 text-xs font-medium text-white   rounded-md focus:outline-none "
                                    >
                                      <svg
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-5 h-5"
                                      >
                                        <path
                                          d="M10.5 11.375C10.9832 11.375 11.375 10.9832 11.375 10.5C11.375 10.0168 10.9832 9.625 10.5 9.625C10.0168 9.625 9.625 10.0168 9.625 10.5C9.625 10.9832 10.0168 11.375 10.5 11.375Z"
                                          stroke="#757E7E"
                                          stroke-width="1.75"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M16.625 11.375C17.1082 11.375 17.5 10.9832 17.5 10.5C17.5 10.0168 17.1082 9.625 16.625 9.625C16.1418 9.625 15.75 10.0168 15.75 10.5C15.75 10.9832 16.1418 11.375 16.625 11.375Z"
                                          stroke="#757E7E"
                                          stroke-width="1.75"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M4.375 11.375C4.85825 11.375 5.25 10.9832 5.25 10.5C5.25 10.0168 4.85825 9.625 4.375 9.625C3.89175 9.625 3.5 10.0168 3.5 10.5C3.5 10.9832 3.89175 11.375 4.375 11.375Z"
                                          stroke="#757E7E"
                                          stroke-width="1.75"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </button>
                                    {/* <!-- Dropdown menu --> */}
                                    <div className="absolute  z-[100] w-40 p-2 hidden group-hover:block right-10 bg-white rounded-md shadow-xl ">
                                      <button
                                        onClick={() => getHandler(item._id)}
                                        className="text-left w-full block px-4 py-2 text-sm text-gray-800 capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                                      >
                                        View Details
                                      </button>
                                      <button
                                        onClick={() => {
                                          setAnchorId(item._id);
                                          getEditHandler(item._id);
                                        }}
                                        className="text-left w-full block px-4 py-2 text-sm text-gray-800 capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                                      >
                                        Edit Anchor
                                      </button>
                                      <button
                                        onClick={() => {
                                          setAnchorId(item._id);
                                          setDeleteModal(true);
                                        }}
                                        className="text-left w-full block px-4 py-2 text-sm text-[#CB444A] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                                      >
                                        Delete Anchor
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-2xl sm:p-6  sm:align-middle">
              <div className="flex items-start justify-between ">
                <div className=" flex justify-between font-semibold py-2">
                  <p className="text-xs  text-gray-900 ">
                    Devotional {details._id}
                  </p>
                </div>
              </div>

              <div className="">
                <div className=" my-3 h-full rounded-lg  bg-white   ">
                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10 rounded-t-md items-center py-1">
                    <p className="text-[10px] text-gray-700 font-bold">Topic</p>
                    <div className="text-right">
                      <p className="text-xs font-bold">{details.topic}</p>
                    </div>
                  </div>
                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10  items-center py-1 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">Text</p>
                    <div className="text-right">
                      <p className="text-xs font-bold">
                        {details.bibleReading}
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10 items-center py-1 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Date Added
                    </p>
                    <div className="text-right">
                      <p className="text-xs font-bold">
                        <FormattedDate date={details?.date} />
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-x-5 justify-between px-3 bg-[#4CC5D2]/10  py-2 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Word Of The Day
                    </p>
                    <div className="text-left">
                      <p className="text-xs font-semibold">
                        {details.wordOfTheDay}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-x-5 justify-between px-3 bg-[#4CC5D2]/10  py-2 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Inspiring Commentary
                    </p>
                    <div className="text-left">
                      <p className="text-xs font-semibold">
                        {details.inspiringCommentary}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10 items-center py-1 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Prayer
                    </p>
                    <div className="text-right">
                      <p className="text-xs font-bold">{details.prayer}</p>
                    </div>
                  </div>
                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10 items-center py-1 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Bible In One Year
                    </p>
                    <div className="text-right">
                      <p className="text-xs font-bold">
                        {details.bibleInOneYear}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-end gap-x-4 ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(false);
                  }}
                  className="flex transition-colors duration-200   hover:text-[#4CC5D2]/50 focus:outline-none text-[#3B3B8E] border border-[#3B3B8E] px-12 py-2 rounded-full gap-x-1 items-center "
                >
                  <span>Cancel</span>
                </button>
                <button
                  onClick={() => {
                    setAnchorId(details._id);
                    getEditHandler(details._id);
                    setModal(false);
                  }}
                  className="flex transition-colors duration-200   hover:text-[#4CC5D2]/50 focus:outline-none text-[#fff]  bg-[#3B3B8E] px-16 py-2 rounded-full gap-x-1 items-center "
                >
                  <span>Edit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Confirm Anchor Delete
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to delete this Anchor?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const data = anchorId;
                    deleteHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Anchor Deleted Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully Deleted this Anchor
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    setDeleteSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {create && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-md sm:p-6  sm:align-middle">
              <div className="flex items-start justify-between ">
                <div className=" flex justify-between font-semibold py-2">
                  <p className="text-xs  text-gray-900 ">Add Devotional</p>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCreate(false);
                  }}
                  type="button"
                  className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <form onSubmit={handleSubmit(createHandler)} className="mt-4">
                <div className="mb-5">
                  <label className="relative bg-white w-16 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Date
                  </label>
                  <input
                    type="date"
                    required
                    {...register("date")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  />
                </div>
                <div className="mb-5">
                  <label className="relative bg-white w-16 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Topic
                  </label>
                  <input
                    type="text"
                    required
                    {...register("topic")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  />
                </div>
                <div className="mb-5">
                  <label className="relative bg-white w-24 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Bible Reading
                  </label>
                  <input
                    type="text"
                    required
                    {...register("bibleReading")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  />
                </div>
                <div className="mb-2">
                  <label className="relative bg-white w-28 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Word Of The Day
                  </label>
                  <textarea
                    type="text"
                    required
                    {...register("wordOfTheDay")}
                    rows={3}
                    className="py-3 text-sm px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-lg w-full focus:outline-none"
                  />
                </div>

                <div className="mb-5">
                  <label className="relative bg-white w-36 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Inspiring Commentary
                  </label>
                  <textarea
                    type="text"
                    required
                    rows={2}
                    {...register("inspiringCommentary")}
                    className="py-3 text-sm px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-lg w-full focus:outline-none"
                  />
                </div>
                <div className="mb-5">
                  <label className="relative bg-white w-12 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Prayer
                  </label>
                  <input
                    type="text"
                    required
                    {...register("prayer")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  />
                </div>
                <div className="mb-5">
                  <label className="relative bg-white w-32 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Bible In One Year
                  </label>
                  <input
                    type="text"
                    required
                    {...register("bibleInOneYear")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  />
                </div>
                <div className="flex gap-x-2  w-full">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCreate(false);
                    }}
                    className="w-full flex item-center text-sm justify-center rounded-3xl p-3 text-[#3B3B8E] border border-[#3B3B8E] font-bold"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value="submit"
                    disabled={loading}
                    className="w-full bg-[#3B3B8E] text-sm rounded-3xl p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                  >
                    {loading ? <Spinner /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {edit && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-md sm:p-6  sm:align-middle">
              <div className="flex items-start justify-between ">
                <div className=" flex justify-between font-semibold py-2">
                  <p className="text-xs  text-gray-900 ">Edit Devotional</p>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setEdit(false);
                    reset();
                  }}
                  type="button"
                  className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <form onSubmit={handleSubmit(updateHandler)} className="mt-4">
                <div className="mb-5">
                  <label className="relative bg-white w-16 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Date
                  </label>
                  <input
                    type="date"
                    required
                    {...register("date")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  />
                </div>
                <div className="mb-5">
                  <label className="relative bg-white w-16 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Topic
                  </label>
                  <input
                    type="text"
                    required
                    {...register("topic")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  />
                </div>
                <div className="mb-5">
                  <label className="relative bg-white w-24 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Bible Reading
                  </label>
                  <input
                    type="text"
                    required
                    {...register("bibleReading")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  />
                </div>
                <div className="mb-2">
                  <label className="relative bg-white w-28 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Word Of The Day
                  </label>
                  <textarea
                    type="text"
                    required
                    {...register("wordOfTheDay")}
                    rows={3}
                    className="py-3 text-sm px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-lg w-full focus:outline-none"
                  />
                </div>

                <div className="mb-5">
                  <label className="relative bg-white w-36 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Inspiring Commentary
                  </label>
                  <textarea
                    type="text"
                    required
                    rows={2}
                    {...register("phone")}
                    className="py-3 text-sm px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-lg w-full focus:outline-none"
                  />
                </div>
                <div className="mb-5">
                  <label className="relative bg-white w-12 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Prayer
                  </label>
                  <input
                    type="text"
                    required
                    {...register("prayer")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  />
                </div>
                <div className="mb-5">
                  <label className="relative bg-white w-32 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Bible In One Year
                  </label>
                  <input
                    type="text"
                    required
                    {...register("bibleInOneYear")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  />
                </div>

                <div className="flex gap-x-2  w-full">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setEdit(false);
                      reset();
                    }}
                    className="w-full flex item-center text-sm justify-center rounded-3xl p-3 text-[#3B3B8E] border border-[#3B3B8E] font-bold"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value="submit"
                    disabled={load}
                    className="w-full bg-[#3B3B8E] text-sm rounded-3xl p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                  >
                    {load ? <Spinner /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Devotionals;
