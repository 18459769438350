import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import PageLoading from '../../../components/PageLoading';
import {
	useGetAllAdminsQuery,
	useRemoveAdminMutation,
} from '../../../Slices/apisSlice';

const FormattedDate = ({ date }) => {
	const formatDate = (date) => {
		const parsedDate = parseISO(date);
		return format(parsedDate, 'MMM d yyyy | h:mmaaa');
	};
	return <span>{formatDate(date)}</span>;
};

function ManageAccounts() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);
	const [user, setUser] = useState([]);
	const [openTab, setOpenTab] = useState(1);

	const [userId, setUserId] = useState('');
	const [adminSucess, setAdminSucess] = useState(false);
	const [adminModal, setAdminModal] = useState(false);
	const { data, isLoading, refetch, error } = useGetAllAdminsQuery();

	useEffect(() => {
		if (data) {
			setUser(data);
		}
	}, [data]);

	const [adminuser] = useRemoveAdminMutation();
	const adminHandler = async (admindata) => {
		try {
			await adminuser(admindata);
			setAdminModal(false);
			setAdminSucess(true);
			// refetch();
		} catch (err) {
			toast.error(err?.data?.message || err.error);
		}
	};

	return (
		<div>
			<div className="flex justify-between items-center px-5 mt-5  ">
				<div className="flex items-center gap-x-10">
					<h2 className="text-gray-900   text-lg font-extrabold text-left rounded-2xl focus:outline-none block  ">
						Admins
					</h2>
					<div className="flex gap-x-2">
						<button
							onClick={(e) => {
								e.preventDefault();
								setOpenTab(1);
							}}
							className={`w-[6rem] px-3  py-1.5  text-[15px] font-semibold ${
								openTab === 1
									? 'shadow-sm rounded-lg  text-[#414D52] bg-[#DFDFFF]'
									: 'text-[#69797F]'
							} transition-colors duration-200  dark:hover:bg-gray-800   hover:bg-gray-100`}
						>
							Total ({/* {vendor.length} */}30)
						</button>
						{/* <button
							onClick={(e) => {
								e.preventDefault();
								setOpenTab(2);
							}}
							className={`w-[7rem] px-3  py-1.5  text-[15px] font-semibold ${
								openTab === 2
									? 'shadow-sm rounded-lg  text-[#414D52] bg-[#DFDFFF]'
									: 'text-[#69797F]'
							} transition-colors duration-200  dark:hover:bg-gray-800   hover:bg-gray-100`}
						>
							Active (90)
						</button>
						<button
							onClick={(e) => {
								e.preventDefault();
								setOpenTab(3);
							}}
							className={`w-[7rem] px-3  py-1.5  text-[15px] font-semibold ${
								openTab === 3
									? 'shadow-sm rounded-lg  text-[#414D52] bg-[#DFDFFF]'
									: 'text-[#69797F]'
							} transition-colors duration-200  dark:hover:bg-gray-800   hover:bg-gray-100`}
						>
							Inactive (10)
						</button> */}
					</div>
				</div>

				{/* <button
					
					className="flex transition-colors duration-200   hover:text-gray-300 focus:outline-none text-[#fff]  bg-[#3B3B8E] px-3 py-1 rounded-md gap-x-1 items-center "
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8 3.33331V12.6666"
							stroke="CurrentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M3.33325 8H12.6666"
							stroke="CurrentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					<span>Add Admins</span>
				</button> */}
			</div>

			{openTab === 1 && (
				<div className="p-2 lg:p-6  max-w-6xl mt-5">
					<div className="p-2 bg-[#fff] rounded-2xl">
						<div className="flex justify-between items-center my-3 px-5">
							<div className="relative w-[30rem]">
								<div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.8866 15.2537 12.5988 14.0402 13.8576C14.0064 13.8837 13.9738 13.9123 13.9428 13.9433C13.9118 13.9743 13.8833 14.0068 13.8572 14.0406C12.5984 15.2539 10.8863 16 9 16C5.13401 16 2 12.866 2 9ZM14.6174 16.0321C13.0778 17.2636 11.1249 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.1251 17.2634 13.0782 16.0317 14.618L19.707 18.2933C20.0975 18.6838 20.0975 19.317 19.707 19.7075C19.3165 20.098 18.6833 20.098 18.2928 19.7075L14.6174 16.0321Z"
											fill="#7B7C7C"
										/>
									</svg>
								</div>
								<input
									type="text"
									id="simple-search"
									className="bg-white text-gray-900 text-sm rounded-2xl focus:outline-none block w-full pl-10 p-3 placeholder-bold"
									placeholder="Search"
									required
								/>
							</div>

							<div className="flex justify-between   py-1 items-center gap-x-3 ">
								<button
									className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
									aria-expanded="false"
								>
									Show: 10
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M12.2932 5.33203C12.7124 5.33203 12.9455 5.817 12.6836 6.14438L8.3906 11.5107C8.19043 11.7609 7.80989 11.7609 7.60973 11.5107L3.31671 6.14438C3.0548 5.817 3.28789 5.33203 3.70714 5.33203L12.2932 5.33203Z"
											fill="#7B7B7B"
										/>
									</svg>
								</button>
								<button
									className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
									aria-expanded="false"
								>
									Filters
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M3.59993 1.3999H12.3999C13.1333 1.3999 13.7333 1.9999 13.7333 2.73324V4.1999C13.7333 4.73324 13.3999 5.3999 13.0666 5.73324L10.1999 8.26657C9.79993 8.5999 9.53327 9.26657 9.53327 9.7999V12.6666C9.53327 13.0666 9.2666 13.5999 8.93327 13.7999L7.99994 14.3999C7.13327 14.9332 5.93327 14.3332 5.93327 13.2666V9.73324C5.93327 9.26657 5.6666 8.66657 5.39994 8.33324L2.8666 5.66657C2.53327 5.33324 2.2666 4.73324 2.2666 4.33324V2.7999C2.2666 1.9999 2.8666 1.3999 3.59993 1.3999Z"
											stroke="#7B7B7B"
											stroke-width="1.5"
											stroke-miterlimit="10"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M7.28667 1.3999L4 6.66657"
											stroke="#7B7B7B"
											stroke-width="1.5"
											stroke-miterlimit="10"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</button>
								<button
									className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
									aria-expanded="false"
								>
									Sort
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M2 4.6665H14"
											stroke="#7B7B7B"
											stroke-width="1.5"
											stroke-linecap="round"
										/>
										<path
											d="M4 8H12"
											stroke="#7B7B7B"
											stroke-width="1.5"
											stroke-linecap="round"
										/>
										<path
											d="M6.66699 11.3335H9.33366"
											stroke="#7B7B7B"
											stroke-width="1.5"
											stroke-linecap="round"
										/>
									</svg>
								</button>
							</div>
						</div>

						<section class="container bg-[#FFFFFF] lg:bg-transparent rounded mx-auto h-[50rem]">
							<div class="flex flex-col">
								<div class="lg:-mx-4 -my-2 overflow-x-auto scrollbar-hide sm:-mx-6 lg:-mx-8">
									<div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
										<div class="overflow-hidden md:rounded-lg">
											{isLoading ? (
												<div className="pb-5">
													<PageLoading />
												</div>
											) : error ? (
												<div className="pt-5">
													<PageLoading />
												</div>
											) : (
												<table class="min-w-full ">
													<thead>
														<tr>
															<th
																scope="col"
																class="py-3.5 px-4 text-xs lg:text-sm font-normal text-left rtl:text-right text-gray-500 "
															>
																#
															</th>
															<th
																scope="col"
																class="px-3 py-3.5 text-xs lg:text-sm  font-normal text-left rtl:text-right text-gray-500 "
															>
																Name
															</th>
															<th
																scope="col"
																class="px-3 py-3.5 text-xs lg:text-sm  font-normal text-left rtl:text-right text-gray-500 "
															>
																Mobile Number
															</th>
															<th
																scope="col"
																class="px-3 py-3.5 text-xs lg:text-sm  font-normal text-left rtl:text-right text-gray-500 "
															>
																Role
															</th>

															<th
																scope="col"
																class="px-3 py-3.5 text-xs lg:text-sm  gap-1 font-normal  text-left rtl:text-right text-gray-500  "
															>
																<div class="flex items-center gap-x-2  ">
																	Date
																</div>
															</th>
														</tr>
													</thead>

													<tbody class="  ">
														{user.map((item, index) => (
															<tr
																className={index % 2 === 0 ? '' : 'bg-gray-50'}
																key={index}
															>
																<td class="pl-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
																	{index + 1}
																</td>

																<td class="px-3 py-1 text-sm text-[#337FEF] whitespace-nowrap ">
																	<div className="flex items-center gap-x-2">
																		<div
																			className="rounded-full bg-center bg-cover h-[2rem] w-[2rem] flex items-center justify-center"
																			style={{
																				'background-image':
																					"url('../Avatar.png')",
																			}}
																		>
																			<span className=" text-white ">
																				{item.name
																					.split(' ')
																					.map((i) => i.charAt(0))}
																			</span>
																		</div>
																		<div className="flex flex-col -space-y-1">
																			<span className="font-bold text-[#022045]">
																				{item.name}
																			</span>
																			<span className="font-light text-[#69797F]">
																				{item.email}
																			</span>
																		</div>
																	</div>
																</td>
																<td class="px-3 py-4 text-xs text-gray-500  whitespace-nowrap">
																	{item.phone}
																</td>
																<td class="px-3 py-4 text-xs text-gray-500  whitespace-nowrap">
																	{item.isAdmin === true ? 'Admin' : ''}
																</td>

																<td class="px-3 py-4 text-sm text-gray-500  whitespace-nowrap">
																	<FormattedDate date={item?.createdAt} />
																</td>

																<td class="px-3 py-1 text-sm text-gray-500  whitespace-nowrap">
																	<div className=" inline-block group ">
																		<button
																			type="submit"
																			className="px-0.5 ml-1 text-xs font-medium text-white   rounded-md focus:outline-none "
																		>
																			<svg
																				width="21"
																				height="21"
																				viewBox="0 0 21 21"
																				fill="none"
																				xmlns="http://www.w3.org/2000/svg"
																				className="w-5 h-5"
																			>
																				<path
																					d="M10.5 11.375C10.9832 11.375 11.375 10.9832 11.375 10.5C11.375 10.0168 10.9832 9.625 10.5 9.625C10.0168 9.625 9.625 10.0168 9.625 10.5C9.625 10.9832 10.0168 11.375 10.5 11.375Z"
																					stroke="#757E7E"
																					stroke-width="1.75"
																					stroke-linecap="round"
																					stroke-linejoin="round"
																				/>
																				<path
																					d="M16.625 11.375C17.1082 11.375 17.5 10.9832 17.5 10.5C17.5 10.0168 17.1082 9.625 16.625 9.625C16.1418 9.625 15.75 10.0168 15.75 10.5C15.75 10.9832 16.1418 11.375 16.625 11.375Z"
																					stroke="#757E7E"
																					stroke-width="1.75"
																					stroke-linecap="round"
																					stroke-linejoin="round"
																				/>
																				<path
																					d="M4.375 11.375C4.85825 11.375 5.25 10.9832 5.25 10.5C5.25 10.0168 4.85825 9.625 4.375 9.625C3.89175 9.625 3.5 10.0168 3.5 10.5C3.5 10.9832 3.89175 11.375 4.375 11.375Z"
																					stroke="#757E7E"
																					stroke-width="1.75"
																					stroke-linecap="round"
																					stroke-linejoin="round"
																				/>
																			</svg>
																		</button>

																		<div className="absolute  z-[100] w-40 p-2 hidden group-hover:block right-10 bg-white rounded-md shadow-xl ">
																			<button
																				onClick={() => {
																					setUserId(item.id);
																					setAdminModal(true);
																				}}
																				className="block w-full px-4 py-2 text-sm text-[#CB444A] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
																			>
																				Remove Admin
																			</button>
																		</div>
																	</div>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											)}
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			)}

			{adminModal && (
				<div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
					<div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
						<span
							className="hidden sm:inline-block sm:h-screen sm:align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>

						<div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
							<div className="flex items-start justify-between ">
								<h3 className="text-[#000] text-xl font-semibold dark:text-white">
									Remove Admin
								</h3>
							</div>

							<p className="text-left py-5 text-sm text-[#141414]/70">
								Are you sure you want to remove this User as Admin ?
							</p>
							<div className="flex gap-x-3 items-start justify-between">
								<button
									onClick={(e) => {
										e.preventDefault();
										setAdminModal(false);
									}}
									className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
								>
									No, Cancel
								</button>
								<button
									onClick={() => {
										const data = userId;
										adminHandler(data);
									}}
									className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-red-500  px-3 py-4 rounded-lg "
								>
									Yes, Confirm
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{adminSucess && (
				<div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
					<div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
						<span
							className="hidden sm:inline-block sm:h-screen sm:align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>

						<div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
							<div className="flex items-start justify-between ">
								<h3 className="text-[#000] text-xl font-semibold dark:text-white">
									Admin Removed Successfully
								</h3>
							</div>

							<p className="text-left py-5 text-sm text-[#D3B61F]">
								You have successfully removed this User as an Admin
							</p>
							<div className="flex gap-x-3 items-start justify-between">
								<button
									onClick={(e) => {
										setAdminSucess(false);
										refetch();
									}}
									className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
								>
									Continue
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default ManageAccounts;
