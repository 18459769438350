import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import PageLoading from "../../components/PageLoading";
import Spinner from "../../components/Spinner";
import {
  useCreateImageMutation,
  useDeleteImageMutation,
  useGetAllOngoingImagesQuery,
  useGetAllProposedImagesQuery,
} from "../../Slices/apisSlice";
import { BASE_URL } from "../../utils";

function Projects() {
  const [openTab, setOpenTab] = useState(1);
  const [ongoing, setOngoing] = useState([]);
  const [proposed, setProposed] = useState([]);
  const [imageId, setImageId] = useState("");
  const [deleteSucess, setDeleteSucess] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { data, isLoading, refetch, isError } = useGetAllOngoingImagesQuery();
  const [edit, setEdit] = useState(false);
  const [create, setCreate] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const {
    data: result,
    isLoading: Isloading,
    refetch: reFetch,
    error,
  } = useGetAllProposedImagesQuery();
  const { register, handleSubmit, setValue, reset } = useForm();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (data) {
      setOngoing(data.images);
    }
    if (result) {
      setProposed(result.images);
    }
  }, [data, result]);
  console.log(proposed);
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "f6lismup"); // Replace with your Cloudinary upload preset
    setLoadingUpload(true);
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dqzrjcuff/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setValue("imageUri", data.secure_url);
      setLoadingUpload(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const [createImage, { isLoading: loading }] = useCreateImageMutation();
  const createHandler = async (data) => {
    try {
      await createImage(data);
      toast.success("Successfully Added Image");
      setCreate(false);
      refetch();
      reFetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error, {
        position: "top-left",
      });
    }
  };

  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.token;

  const updateHandler = async (data) => {
    try {
      setLoad(true);
      const res = await axios.put(`${BASE_URL}admin/b/${imageId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const message = res.data.message;
      setLoad(false);
      toast.success(message);
      setEdit(false);
      refetch();
      reFetch();
    } catch (err) {
      toast.error(err?.message || err?.data.message);
    }
  };

  const [deleteImage] = useDeleteImageMutation();
  const deleteHandler = async (deletedata) => {
    try {
      await deleteImage(deletedata);
      setDeleteModal(false);
      setDeleteSucess(true);
      refetch();
      reFetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center px-5 mt-5  ">
        <div className="flex items-center gap-x-10">
          <h2 className="text-gray-900   text-lg font-extrabold text-left rounded-2xl focus:outline-none block  ">
            Projects
          </h2>
          <div className="flex gap-x-2">
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              className={`w-[6rem] px-3  py-1.5  text-[15px] font-semibold ${
                openTab === 1
                  ? "shadow-sm rounded-lg  text-[#414D52] bg-[#DFDFFF]"
                  : "text-[#69797F]"
              } transition-colors duration-200  dark:hover:bg-gray-800   hover:bg-gray-100`}
            >
              Ongoing
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              className={`w-[6rem] px-3  py-1.5  text-[15px] font-semibold ${
                openTab === 2
                  ? "shadow-sm rounded-lg  text-[#414D52] bg-[#DFDFFF]"
                  : "text-[#69797F]"
              } transition-colors duration-200  dark:hover:bg-gray-800   hover:bg-gray-100`}
            >
              Proposed
            </button>
          </div>
        </div>

        <button
          onClick={() => {
            setCreate(true);
          }}
          className="flex transition-colors duration-200   hover:text-[#4CC5D2]/50 focus:outline-none text-[#fff]  bg-[#3B3B8E] px-3 py-1 rounded-md gap-x-1 items-center "
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 3.33331V12.6666"
              stroke="CurrentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.33325 8H12.6666"
              stroke="CurrentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Add Image</span>
        </button>
      </div>
      <div className="p-2 lg:p-6  w-full">
        <div className="p-2 bg-[#fff] rounded-2xl">
          {openTab === 1 && (
            <section className="container bg-[#FFFFFF]  rounded mx-auto  p-5">
              <div className="py-3">
                {isLoading ? (
                  <div className="pb-5">
                    <PageLoading />
                  </div>
                ) : isError ? (
                  <div className="pt-5">
                    <Loading />
                  </div>
                ) : (
                  <div className="grid grid-cols-12 gap-2 overflow-x-auto rounded-lg h-[52rem] scrollbar-hide">
                    {ongoing
                      .slice()
                      .reverse()
                      .map((item, index) => (
                        <div key={index} className="col-span-2 bg-white ">
                          <div className="shadow-lg group container  rounded-md bg-white  max-w-sm flex justify-center items-center  mx-auto ">
                            <div>
                              <img
                                className="w-[12rem] h-auto flex items-center justify-center rounded-lg"
                                src={item.imageUri}
                                alt="card"
                              />
                            </div>
                            <div className="absolute opacity-0 fd-sh group-hover:opacity-100">
                              <div className=" text-center">
                                <button
                                  onClick={() => {
                                    setImageId(item._id);
                                    setDeleteModal(true);
                                  }}
                                  className="text-center rounded-lg p-1 bg-white  text-gray-700 font-bold text-xs"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-3  sm:w-5 sm:h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </section>
          )}

          {openTab === 2 && (
            <section className="container bg-[#FFFFFF]  rounded mx-auto  p-5">
              <div className="py-3">
                {Isloading ? (
                  <div className="pb-5">
                    <PageLoading />
                  </div>
                ) : error ? (
                  <div className="pt-5">
                    <Loading />
                  </div>
                ) : (
                  <div className="grid grid-cols-12 gap-2 overflow-x-auto rounded-lg h-[52rem] scrollbar-hide">
                    {proposed
                      .slice()
                      .reverse()
                      .map((item, index) => (
                        <div key={index} className="col-span-2 bg-white ">
                          <div className="shadow-lg group container  rounded-md bg-white  max-w-sm flex justify-center items-center  mx-auto ">
                            <div>
                              <img
                                className="w-[12rem] h-auto flex items-center justify-center rounded-lg"
                                src={item.imageUri}
                                alt="card"
                              />
                            </div>
                            <div className="absolute opacity-0 fd-sh group-hover:opacity-100">
                              <div className=" text-center">
                                <button
                                  onClick={() => {
                                    setImageId(item._id);
                                    setDeleteModal(true);
                                  }}
                                  className="text-center rounded-lg p-1 bg-white  text-gray-700 font-bold text-xs"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-3  sm:w-5 sm:h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </section>
          )}
        </div>
      </div>

      {create && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-md sm:p-6  sm:align-middle">
              <div className="flex items-start justify-between ">
                <div className=" flex justify-between font-semibold py-2">
                  <p className="text-xs  text-gray-900 ">Add Image</p>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCreate(false);
                  }}
                  type="button"
                  className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <form onSubmit={handleSubmit(createHandler)} className="mt-4">
                <div className="mb-5">
                  <label className="relative bg-white w-20 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Image Type
                  </label>
                  <select
                    type="text"
                    required
                    {...register("imageType")}
                    className="py-3 px-5  border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  >
                    <option value="">Select...</option>
                    <option value="proposed">Proposed</option>
                    <option value="ongoing">Ongoing</option>
                  </select>
                </div>

                <div className="w-full mb-4">
                  <label className="relative bg-white text-center py-2 w-36  rounded-full px-auto  z-10 block text-xs text-[#495454] font-normal">
                    Main Thumbnail (Picture)
                  </label>
                  <input
                    type="text"
                    {...register("imageUri")}
                    className="hidden "
                  />
                  <div className="flex">
                    <label
                      className="cursor-pointer inline-flex items-center  -mr-24 px-3 py-2 bg-[#E4E4E4] text-gray-500 rounded-l-2xl font-semibold text-xs  hover:bg-gray-300 active:bg-[#E4E4E4] focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 relative  w-[6rem] z-10"
                      for="thumbnail"
                    >
                      Choose File
                    </label>
                    <input
                      id="thumbnail"
                      type="file"
                      onChange={handleUpload}
                      className="py-3 px-8 border-[0.5px] bg-[#F6F8F8]/50 border-[#757E7E]/20 rounded-2xl w-full focus:outline-none text-xs"
                    />
                  </div>
                </div>

                <div className="flex gap-x-2  w-full">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCreate(false);
                    }}
                    className="w-full flex item-center text-sm justify-center rounded-3xl p-3 text-[#3B3B8E] border border-[#3B3B8E] font-bold"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value="submit"
                    disabled={loading || loadingUpload}
                    className="w-full bg-[#3B3B8E] text-sm rounded-3xl p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                  >
                    {loading ? <Spinner /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {edit && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-md sm:p-6  sm:align-middle">
              <div className="flex items-start justify-between ">
                <div className=" flex justify-between font-semibold py-2">
                  <p className="text-xs  text-gray-900 ">Edit Image</p>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setEdit(false);
                  }}
                  type="button"
                  className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <form onSubmit={handleSubmit(updateHandler)} className="mt-4">
                <div className="mb-5">
                  <label className="relative bg-white w-16 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Image Type
                  </label>
                  <select
                    type="text"
                    required
                    {...register("imageType")}
                    className="py-3 px-5  border-[0.5px] bg-[#F6F8F8] border-[#3B3B8E] rounded-full w-full focus:outline-none"
                  >
                    <option value="">Select...</option>
                    <option value="proposed">Proposed</option>
                    <option value="ongoing">Ongoing</option>
                  </select>
                </div>

                <div className="w-full my-2">
                  <label className="relative bg-white text-center py-2 w-36  rounded-full px-auto  z-10 block text-xs text-[#495454] font-normal">
                    Main Thumbnail (Picture)
                  </label>
                  <input
                    type="text"
                    {...register("imageUri")}
                    className="hidden "
                  />
                  <div className="flex">
                    <label
                      className="cursor-pointer inline-flex items-center  -mr-24 px-3 py-2 bg-[#E4E4E4] text-gray-500 rounded-l-2xl font-semibold text-xs  hover:bg-gray-300 active:bg-[#E4E4E4] focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 relative  w-[6rem] z-10"
                      for="thumbnail"
                    >
                      Choose File
                    </label>
                    <input
                      id="thumbnail"
                      type="file"
                      onChange={handleUpload}
                      className="py-3 px-8 border-[0.5px] bg-[#F6F8F8]/50 border-[#757E7E]/20 rounded-2xl w-full focus:outline-none text-xs"
                    />
                  </div>
                </div>

                <div className="flex gap-x-2  w-full">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setEdit(false);
                      reset();
                    }}
                    className="w-full flex item-center text-sm justify-center rounded-3xl p-3 text-[#3B3B8E] border border-[#3B3B8E] font-bold"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value="submit"
                    disabled={load || loadingUpload}
                    className="w-full bg-[#3B3B8E] text-sm rounded-3xl p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                  >
                    {load ? <Spinner /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {deleteModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold">
                  Confirm Image Delete
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to delete this Image ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const data = imageId;
                    deleteHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold">
                  Image Deleted Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully Deleted this Image
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    setDeleteSucess(false);
                    refetch();
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Projects;
