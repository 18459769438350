import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { logout } from "../Slices/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleIdle = () => {
    setIdle(true);
    dispatch(logout());
    navigate("/");
  };
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout / 2,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleTimeout;
