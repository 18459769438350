import { configureStore } from '@reduxjs/toolkit';
import { apiOne, apiTwo } from '../Slices/apiSlice';
import authReducer from '../Slices/authSlice';

export const store = configureStore({
	reducer: {
		[apiOne.reducerPath]: apiOne.reducer,
		[apiTwo.reducerPath]: apiTwo.reducer,

		auth: authReducer,
	},

	middleware: (getdefaultMiddleware) =>
		getdefaultMiddleware().concat([apiTwo.middleware, apiOne.middleware]),
});
